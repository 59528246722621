.search-label{
  font-weight: 600;
}
.app-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(216, 216, 216, 0.328);
  align-items: center;
  justify-content: center;
  display: flex;
}

.app-loader img {
  z-index: 10000;
  animation:  .4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 200px
}

@keyframes app-loading-animation {
  from {
    width: 200px
  }

  to {
    width: 240px
  }
}

.app-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.app-page-body {
  padding-top: 98px;
}



/* Home Slider Bullets */
.fs-pager-wrapper {
  display: none !important;
}

/* @import "~bootstrap/scss/bootstrap"; */
/* Header */
/* .carousel {
  width:100%;
  height:500px;
  margin: auto;
} */
/* SEARCH HOME PAGE */
.header nav ul li a {
  font-size: 20px;
}

.footer-social-media {
  font-size: 24px;
  display: flex;
  color: white;
  flex-direction: column;
  padding-left: 0px;
  /* justify-content: space-between; */
}

.footer-social-media a {
  padding-right: 8px;
  color: #ffffff;
}

.c-white {
  color: #ffffff;
}

.fab-submit-btn {
  padding: 1em 1em 1em 1em;
  background-color: rgb(147, 0, 2);
  font-size: 16px;
  color: white;
  font-weight: 700;
  /* border-color: black; */
  border-radius: 117px;
  z-index: 1;
  box-shadow: rgb(156 5 5 / 66%) -5px 1px 20px 0px;
  border: none;
}

.fab-submit-btn:hover {
  background-color: #d70304;
  box-shadow: rgb(156 5 5 / 36%) -5px 1px 20px 0px;
  color: white;
}

.card-body {
  padding: 0px 12px 7px 12px !important;
}

.currency-input {
  margin-bottom: 4px;
  width: 100%;
  padding: 6px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  border: rgb(218, 218, 218) solid;
  border-width: 1px;
}

i {
  padding-right: 3px;
}

.category-search-items {
  padding: 10px 10px 10px 10px;
  background: #f1f1f1;
}

.search-category-image {
  margin-right: 20px;
  cursor: pointer;
}

.search-category-image img {
  max-width: 50px;
}

.search-category-image-active {
  color: #707070;
  filter: invert(.3) opacity(.6) drop-shadow(0 0 0 rgb(255, 0, 0));
}

.category-search-list {
  display: flex;
  overflow-x: scroll;
}

.category-search-list .item {
  width: 100px;
  text-align: center;
}

.category-search-list .item p {
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.2em;
  /* (Number of lines you want visible) * (line-height) */
  font-weight: bold;
  font-size: 12px;
  /* line-height: 1.2em; */
  text-align: center;
}

.search-category-image :hover {
  color: #707070;
  filter: invert(.3) opacity(.6) drop-shadow(0 0 0 rgb(255, 0, 0));
}

.home-search-category-container {
  margin-top: 10px;
}

.home-rent-container {
  display: flex;
  background-color: #830808;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 8px;
  cursor: pointer;
  /* width: ; */
}

.home-rent-container h6 {
  color: white;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px;

}

.home-rent-container h6 {
  /* border-right: #ffffff solid; */
  font-weight: 500;
  font-family: 'MontserratBlack';
  font-size: 25px;
}

.home-rent-container .rent {
  /* border-right: #ffffff solid; */
  /* background-color: #c3e4ff; */
  /* padding-right: 10px; */
}

.heading-color {
  color: #c95757;
}

.home-rent-container .active {
  background-color: #c95757;
  border-radius: 6px;
  /* border: none; */
}

.intro-slide img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}


/* Header */
.bg_logo {
  background-color: #18202f;
  height: 86% !important;
}

/*  */
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 2px;
}

::-webkit-scrollbar:hover {
  width: 10px !important;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f3f3f3 !important;
  width: 1px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 99, 99, 0.349) !important;
  border-radius: 20px !important;
  width: 3px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 99, 99) !important;
  width: 6px !;
}

/* GENERAL */
.img-oc-w100 {
  width: 100% !important;
  object-fit: cover;
}

.ic-rm4 {
  margin-right: 4px;
  /* font-size: 10px; */
}

.ic-rm4-f10 {
  margin-right: 4px;
  font-size: 30px;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.left-text {
  text-align: left;
}

.lbl-load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
}

.lbl-load-more label:hover {
  color: #d70304;
  cursor: pointer;
  text-decoration: #d70304 underline;
}

/*  */

.app-header a {
  text-decoration: none;
}

main a {
  text-decoration: none;
}

main {
  background-color: #f4f5fb;
}

.productList a {
  text-decoration: none;
}

.home-page .home-cat {
  padding-bottom: 20px;
}

.home-page a {
  text-decoration: none;
}

.home-page .home-cat {
  height: auto;
}

.home-page .home-cat label {
  font-size: 14px;
  /* font-weight: bold; */
  /* font-family: 'MontserratBlack', 'Montserrat', sans-serif; */
  font-weight: 600 !important;
}

.home-page .home-cat .frontcategs-img {
  /* height: 100%; */
  width: 120px;
}

.home-page .home-cat .frontcategs-img img {
  width: 100%;
  object-fit: cover;
  padding: 10px;
  opacity: .6;
  transition: 1s;
}

.home-page .home-cat .frontcategs-img img:hover {
  zoom: .7;
  animation: backwards;
  opacity: 1;
}

.home-page .special {
  padding: 0;
  transition: box-shadow 0.3s ease-in-out 1s;
}

.home-page .special:hover {
  box-shadow: rgb(194, 194, 194) -1px -2px 20px 5px;
}

/* .home-page .special p{
  padding :0;
} */
.home-item-img {
  padding: 0;
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
}

.home-page h3 {
  margin-left: 4px !important;
  margin-right: 4px;
}

.home-page p {
  margin-left: 4px;
  margin-right: 4px;
}

.home-item-profile-container {
  padding-top: 10px;
  padding-right: 8px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.home-item-profile-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
}

.home-item-profile-name {
  padding: 0px;
}

.home-item-post-time {
  color: grey;
  font-size: 13px;
}

.home-item-icon {
  margin-right: 4px;
}

.home-item-info {
  padding-left: 10px;
  padding-bottom: 20px;
  color: grey;
}

.home-menuf-item {
  width: 100%;
  height: 100%;
  padding: 20px;
  width: 120px;
}

.home-menuf-img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 60px;
  background: white;

}

.home-cat .type_cat .frontcategs-img img {
  width: 100px;
  height: 100px;
}

.product-item .product-image .logo-image {
  position: absolute;
  z-index: 999;
  background: #ffffff;
}

.home-menuf-name {
  color: grey;
  font-size: 16px;
}

/* product-detail-page */
.product-detail-page .user-info label {
  font-size: 15px;
}

.product-detail-page .rating-container label {
  font-size: 24px;
}

/* Login Page */
.login-page {
  height: 100%;
}

.login-page-container {
  background-color: #ff3100;
  width: 100%;
  height: auto;
}

html body {
  height: 100%;
}

.heading-font {
  font-family: 'MontserratBlack', 'Montserrat', sans-serif;

}

.form-input {
  margin-top: 10px !important;
}

/* Add Item */
.add-product .package-container {}

.add-product .package-container .package-items {
  padding-top: 10px;
}

.add-product .package-container .package-items .package {}

.package {
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.package-body {
  border-top: #e74e5f solid 8px;
}

.basic-package-bg {
  background: #80b2e8 !important;
}

.basic-package-color {
  color: #e74e5f !important;
}

.basic-package-border {
  border-color: #80b2e8 !important;
}

/* Exclusive plan */
.exc-package-bg {
  background: #99cb67 !important;
}

.exc-package-color {
  color: #99cb67 !important;
}

.exc-package-border {
  border-color: #99cb67 !important;
}

/*  */
/* VIP plan */
.vip-package-bg {
  background: #eac347 !important;
}

.vip-package-color {
  color: #eac347 !important;
}

.vip-package-border {
  border-color: #eac347 !important;
}

/*  */
.package-items .package {
  cursor: pointer;
}

.package .selected-header {
  background: #e74e5f;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 6px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: white;
  /* margin: 0px; */
}

.package-items .package-currency {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  top: -15px;
}

.package .active {
  border: #e74e5f 4px solid;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

}

.package {}

.package .item {
  box-shadow: 5px 5px 14px 0px grey;
  border-radius: 10px;
}

.add-product .add-img-container {
  overflow-x: scroll;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 8px;
  /* height: 160px; */
}

.add-product .add-img-container .img {
  margin-right: 10px;
  max-width: 230px;
  height: 140px
}

.feature-icon {
  color: red;
}

/* .feature-icon .inactive{
  color: rgb(159, 159, 159) !important;
} */
.package-feature {
  /* text-align: center; */
  padding-left: 20px;
  font-size: 12px;
}

.package-feature .inactive {
  color: rgb(159, 159, 159) !important;

}

.add-product .category-img-container {
  height: 120px;
  overflow-x: scroll;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  /* justify-content: center; */
  overflow-y: hidden;
  padding-bottom: 20px;
}

/* frontcategs-img */
.add-product .category-img-container .frontcategs-img {
  height: 80px;
  width: 80px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 4px;
}

/*  */
/* Mobile Version */
@media screen and (max-width: 550px) {
  
  .mob-scroll-row-h180 {
    overflow-x: scroll;
    width: 100%;
    flex-wrap: nowrap !important;
    max-height: 180px;
  }

  .mob-scroll::-webkit-scrollbar {
    width: 5px;
  }

  .mob-scroll-item-h180 {
    max-width: 150px !important;
    justify-content: center;
    text-align: center;
    /* height: 160px; */
  }

  .mob-scroll-row-h330 {
    overflow-x: scroll;
    width: 100%;
    flex-wrap: nowrap !important;
    max-height: 530px;
    margin-right: 20px !important;
  }

  .mob-scroll-item-w250 {
    max-width: 250px !important;
    justify-content: center;
    text-align: center;
  }

  /* OVERRIDE */
  .home-item-info .special {
    margin-right: 20px !important;
    /* min-height: 330px !important; */
  }
}

/* OVERRIDE */
.home-item-info .information {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.home-item-info .information>li {
  display: inline-block;
  position: relative;
  padding-right: 16px;
}

.home-item-info .information>li:not(:last-of-type)::after {
  display: block;
  position: absolute;
  content: "";
  width: 2px;
  height: 2px;
  background: rgb(2, 3, 9);
  border-radius: 50%;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}


.home-item-info .information>span {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.16px;
  font-style: normal;
}

.price-heading {
  color: rgb(200, 40, 20);
  display: flex;
  margin-top: auto;
}

.price-heading .price {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: normal;
  font-style: normal;
}

/* CHAT CONTAINER */
.j-tab {
  /* background-color: #ebebeb; */
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.j-tab-active {
  background-color: #dcdefd;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.chat_page .container {
  max-width: 100%;
  margin: auto;
}

.chat_page img {
  max-width: 100%;
}

.chat_page .inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #c4c4c4;
}

.chat_page .inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}

.chat_page .top_spac {
  margin: 20px 0 0;
}


.chat_page .recent_heading {
  float: left;
  width: 40%;
}

.chat_page .srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}

.chat_page .headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.chat_page .recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}

.chat_page .srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}

.chat_page .srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}

.chat_page .srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_page .chat-product-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.chat_page .chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}

.chat_page .chat_ib h5 span {
  font-size: 13px;
  float: right;
}

.chat_page .chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto
}

.chat_page .chat_img {
  float: left;
  width: 12%;
}

.chat_page .chat_img img {
  border-radius: 40px;
  object-fit: cover;
}

.chat_page .chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_page .chat_people {
  overflow: hidden;
  clear: both;
}

.chat_page .chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}

.chat_page .inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.chat_page .active_chat {
  background: #c3e4ff;
}

.chat_page .incoming_msg {
  padding-bottom: 23px !important;
}

.chat_page .incoming_msg_img {
  display: inline-block;
  width: 6%;

}

.chat_page .incoming_msg_img img {
  border-radius: 50px;
}

.chat_page .received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}

.chat_page .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}

.chat_page .time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.chat_page .received_withd_msg {
  width: 57%;
}

.chat_page .mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}

.chat_page .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}

.chat_page .outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}

.chat_page .sent_msg {
  float: right;
  width: 46%;
}

.chat_page .input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.chat_page .type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}

.chat_page .msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}

.chat_page .messaging {
  padding: 0 0 50px 0;
}

.chat_page .msg_history {
  height: 516px;
  overflow-y: auto;
}

.profile-page .profile-image {
  width: 100%;
  text-align: center;
}

.profile-page .profile-image img {
  border-radius: 100px;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
@media screen and (max-width: 1220px){
  .app-page-body {
    padding-top: 115px;
  }
}
@media screen and (max-width: 870px) {
  .app-page-body {
    padding-top: 165px;
  }
  /* .mob-scroll-row-h180{
    flex-wrap: wrap !important;
  } */
}
@media screen and (max-width: 777px){
  .app-page-body {
    padding-top: 50px; 
  }
}
@media screen and (max-width: 650px){
  .app-page-body {
    padding-top: 100px ;
  }
}

/* CHAT CONTAINER END */




/* Header */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.product-detail-price{
  font-size: 34px !important;
}
.product-detail-phone{
	color: #7e7d7d;
  /* font-size: 40px; */
}
.app-color{
  color: rgb(147, 0, 2);
}
.mcontainer{
  width: 90%;
}
.charac-table {
  width: 100%;
}
.charac-table tr td {
  font-size: 17px;
}
.prod-desc-item-c {
  color: #868ca7;
  padding-left: 8px;
  padding-right: 16px;
  font-size: 17px;
}
.empty-layout{
  width: 100%;
  height: fit-content;
  align-self: center;
}
.empty-layout .content{
  width: 100%;
  text-align: center;
}
.empty-layout .content .message{
  font-size: 12;
  padding: 0px;
}
.empty-layout .content img{
  max-width: 100%;
  max-height: 100px;
  padding-bottom: 10px;
}


/* Other Sites */
.om-button {
  background: #e20d0d;
  box-shadow: 0 1px 2px 0 rgb(232 67 54 / 50%);
  border: none;
  border-radius: 2px !important;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  text-align: center;
  transition: background-color .3s ease;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  text-decoration: none;
}
.contact-box :hover{
  background: #b30c0c;
}
.product-detail-email a:hover {
  outline: none;
  color: #ffffff;
  text-decoration: none;
  transition: color .3s;
}
.normal-font{
  font-family: var(--bs-font-sans-serif);;
}
.sectls-itm .ttl{
  font-weight: bold;
  font-size: 16px;
  color: #212121;
  margin-bottom: 4px;
}
.sectls-itm .sectls-val{
 color: #424141;
 font-size: 14px;
}
.sectls-desc{
  max-width: 200px;
  max-height: 22px;
}
.cursor-pointer{
  cursor: pointer;
}
.img-del-btn{
  padding: 4px 8px;
  position: absolute;
  right: 18px;
  top: 14px;
  background: #80808094;
  color: white;
  border-radius: 10px;
}
.payment-note{
  color:red;
  z-index: 9999;
  padding: 10px 2px 0px 2px;
}